import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DeleteYourAccountComponent } from './delete-your-account/delete-your-account.component';

const routes: Routes = [
  /*{ path: '', redirectTo:'landing-page', pathMatch:'full'},*/
  { path:'', component: LandingPageComponent},
  { path:'terms-and-conditions', component: TermsAndConditionsComponent},
  { path:'privacy-policy', component: PrivacyPolicyComponent},
  { path:'refund-policy', component: RefundPolicyComponent},
  { path:'contact-us', component: ContactUsComponent},
  { path:'delete-your-account', component: DeleteYourAccountComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
