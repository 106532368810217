<div class="container" style="padding-top: 150px;">
    <h2>Contact Us</h2>
    <div class="row">
        <div class="col-md-6">
            <p><strong>Email:</strong> testbot@monkhub.com</p>
            <p><strong>Phone:</strong> +91-9090080015</p>
            <p><strong>Address:</strong> H-294, Building 2, Lane 2, 1st Floor, Westend Marg, Near Saket, Saidulajab, New Delhi-110030</p>
        </div>
        <div class="col-md-6" style="padding-bottom:100px;">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Enter Fullname">
            </div> 
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Enter Email">
            </div> 
            <div class="form-group">
                <textarea class="form-control" placeholder="Describe Your Query"></textarea>
            </div> 
            <div class="form-group">
                <input type="button" class="btn btn-primary" value="Submit">
            </div> 
        </div>
    </div>
</div>

