<div class="container" style="padding-top: 150px;">
    <h2>Delete Your Account</h2>
    <p>If you delete your account, your game data, payment methods and other personal information will be deleted permanently. You can delete your account from a TestBot app only.</p>
    <p>To delete your account from your TestBot app:</p>
    <ol>
        <li>Log in to your account via the mobile app</li>
        <li>Navigate to the profile tab.</li>
        <li>Scroll down and click "Delete Account."</li>
        <li>Click on Delete to delete the account</li>
    </ol>
    </div>
