<div class="container-fluid bg">
    <nav class="container navbar navbar-expand-lg navbar-light header">
        <a class="navbar-brand" href="https://testbot.in"><img src="assets/logo.png"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            </ul>
            <div class="form-inline my-2 my-lg-0 about">
                <a class="btn" href="https://testbot.in#about-us">About Us</a>
                <a class="btn" (click)="goToContact()">Contact Us</a>
            </div>
        </div>
    </nav>
</div>