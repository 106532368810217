<section class="top-margin">
<div class="container-fluid">
    <div class="container section1">
        <div class="row">
            <div class="col-sm-7">
                <h1>Preparation for your next entrance exam, just got easier</h1>
                <P>You’re just one click away! Download the Test Bot mobile
                    app now and avail your very essential set of syallbus
                    updated mock tests.
                </P>
                <a href="https://play.google.com/store/apps/details?id=com.monkhub.testbot" target="_blank"><img class="g-play" src="assets/g-play.png" alt=".." /></a>
            </div>
            <div class="col-sm-5">
                <img class="iphone" src="assets/iphone.png" alt="..">
            </div>
        </div>
    </div>
</div>
<!-- section2 -->
<div class="container-fluid">
    <div class="container section1 ">
        <div class="row banner">
            <div class="col-sm-5">
                <img class="device" src="assets/device.png" alt="..">
            </div>
            <div class="col-sm-7">
                <h1>Why Test Bot?</h1>
                    <ul>
                        <li>Consists 20000+ merit questions designed by academic professionals.</li>
                        <li>  UI designed for simplified testing and performance analysis. </li>
                        <li> Powerful tool that automatically tracks performance and optimizes strengths and weaknesses.
                        </li>
                        <li>Provides personalized tests based on past performances.
                        </li>
                    </ul>
            </div>
        </div>
    </div>
</div>
<div class="container" id="about-us">
<h4 class="all">All that Test Bot brags about</h4>
</div>
<div class="container mt-5">
    <div class="row subject">
        <div class="col-sm-6">
            <h4>Subject test</h4>
            <p>Customize subjects of your preference to practice and demonstrate to colleges your knowledge and skills like an expert</p>
        </div>
        <div class="col-sm-6">
            <img src="assets/subject.png" alt="">
        </div>
    </div>
</div>
<div class="container mt-5">
    <div class="row subject">
         <div class="col-sm-6">
            <img src="assets/accuracy.png" alt="">
        </div>
        <div class="col-sm-6">
            <h4>Accuracy test</h4>
            <p>Practice from thousands of mock tests and aim to get all of the answers correct.  </p>
        </div>
       
    </div>
</div>
<div class="container mt-5">
    <div class="row subject">
         
        <div class="col-sm-6">
            <h4>Speed test</h4>
            <p>Practice multiple papers and learn to manage time within the set duration for excellent results. 
            </p>
        </div>
        <div class="col-sm-6">
            <img src="assets/speed.png" alt="">
        </div>
    </div>
</div>
<div class="container mt-5">
    <div class="row subject">
         <div class="col-sm-6">
            <img src="assets/temparament.png" alt="">
        </div>
        <div class="col-sm-6">
            <h4>Exam temparament test</h4>
            <p>Practice in environments that help conquer exam pressure and stress to be able to perform well. </p>
        </div>
    </div>
</div>
<div class="container mt-5">
    <div class="row get">
         
        <div class="col-sm-6">
            <h4>Get all set, for your next test now!</h4>
            <p>Download now and fuel your learning.             </p>
            <img class="g-play" src="assets/g-play.png" alt=".." />
        </div>
        <div class="col-sm-6">
            <img src="assets/two-phone.png" alt="">
        </div>
    </div>
</div>

</section>