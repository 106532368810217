<div class="container-fluid bg">
    <div class="container">
    <footer class="page-footer font-small blue-grey lighten-5 background ">
    <div class="container text-center text-md-left mt-5 ">
  
      <!-- Grid row -->
      <div class="row mt-3 dark-grey-text">
  
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mb-4">
  
          <!-- Content -->
          <img src="assets/footer-logo.png" alt="">
          <p class="mt-5">Test bot mobile app is here for a better future! 
          </p>
  
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
  
          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Quick Links</h6>
          <p class="mt-5">
            <a class="dark-grey-text" href="#!">Portfolio</a>
          </p>
          <p>
            <a class="dark-grey-text" href="#!">Blogs </a>
          </p>
          <p>
            <a class="dark-grey-text" href="#!">About </a>
          </p><p>
            <a class="dark-grey-text" href="#!">Press </a>
          </p>
        
  
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
  
          <h6 class="text-uppercase font-weight-bold">Information</h6>
          <p class="mt-5">
            <a class="dark-grey-text" routerLink="/terms-and-conditions">Terms and Conditions</a>
          </p>
          <p>
            <a class="dark-grey-text" routerLink="/privacy-policy">Privacy Policy</a>
          </p>
          <p>
            <a class="dark-grey-text" routerLink="/refund-policy">Refund Policy</a>
          </p>
          <!--<p>
            <a class="dark-grey-text" href="#!">Service 5</a>
          </p>-->
  
        </div>

        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 content">
  
          <h6 class="text-uppercase font-weight-bold">Reach us</h6>
       
          <p class="mt-5">
            <i class="fa fa-envelope mr-3"></i>testbot@monkhub.com</p><br/>
          <p>
            <i class="fa fa-phone mr-3"></i> +91-9090080015
          </p>
            <p>
                <i class="fa fa-home mr-3"></i>H-294, Building 2, Lane 2, 1st Floor, Westend Marg, Near Saket, Saidulajab, New Delhi-110030</p>
        </div>
  
      </div>
  
    </div>
  </footer>
  <div class="copy">
      <span>© 2022 Test Bot Company. All rights reserved</span>
      <span style="float: right;"><!--Terms & Conditions | Privacy Policy | Sitemap | Disclaimer-->
        Powered by <a href="https://monkhub.com" target="_blank">Monkhub Innovations Pvt. Ltd.</a>
      </span>
    </div>
</div>
</div>